<template>
  <div>
    <svg-icon icon-class="github" @click="goto" />
  </div>
</template>

<script setup>
const url = ref('ssh://git@192.168.2.199:5422/hire/renata-ui-ts.git');

function goto() {
  window.open(url.value)
}
</script>
