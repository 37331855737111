import { defineStore } from 'pinia';
import { ReadStatus } from '@/utils/webscoket';
import {formatDateTime} from "@/utils/date";
interface NoticeItem {
  fromUserId?: string | number;
  fromUserIp?: string;
  toUserId?: string | number;
  toUserIp?: string;
  type?: string;
  readStatus: any;
  message: any;
  sendTime: string;
  readTime?: string;
}

export const useNoticeStore = defineStore('notice', () => {
  const state = reactive({
    notices: [] as NoticeItem[]
  });

  const addNotice = (notice: NoticeItem) => {
    state.notices.push(notice);
  };

  const removeNotice = (notice: NoticeItem) => {
    state.notices.splice(state.notices.indexOf(notice), 1);
  };

  //实现全部已读
  const readAll = () => {
    state.notices.forEach((item) => {
      item.readStatus = ReadStatus.READ;
      item.readTime = formatDateTime(new Date(),"yyyy-MM-dd HH:mm:ss");
    });
  };

  const clearNotice = () => {
    state.notices = [];
  };


  return {
    state,
    addNotice,
    removeNotice,
    readAll,
    clearNotice
  };
});

export default useNoticeStore;
