<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "layoutBreadcrumbUserNews",
  
})
</script>
<template>
  <div class="layout-navbars-breadcrumb-user-news" v-loading="state.loading">
    <div class="head-box">
      <div class="head-box-title">通知公告</div>
      <div class="head-box-btn" @click="readAllMessage">全部已读</div>
    </div>
    <div class="content-box" v-loading="state.loading">
      <template v-if="newsList.length > 0">
        <div class="content-box-item" v-for="(v, k) in newsList" :key="k" @click="onNewsClick(k)">
          <div class="item-conten">
<!--            <div></div>-->
            <div class="content-box-msg">{{ v.message }}</div>
            <div class="content-box-time">{{ v.sendTime }}</div>
          </div>
          <!-- 已读/未读 -->
          <span v-if="v.readStatus == '0'" class="el-tag el-tag--success el-tag--mini read">已读</span>
          <span v-else class="el-tag el-tag--danger el-tag--mini read">未读</span>
<!--          <span class="el-tag el-tag&#45;&#45;danger el-tag&#45;&#45;mini read">{{ v.readStatus }}</span>-->
        </div>
      </template>
      <el-empty :description="'消息为空'" v-else></el-empty>
    </div>
<!--    <div class="foot-box" @click="onGoToGiteeClick" v-if="newsList.length > 0">前往gitee</div>-->
  </div>
</template>

<script setup lang="ts" name="layoutBreadcrumbUserNews">
import { ref } from "vue";
import { storeToRefs } from 'pinia'
import { nextTick, onMounted, reactive } from "vue";
import useNoticeStore from '@/store/modules/notice';
import useUserStore from "@/store/modules/user";

import {ReadStatus, sendMsg, Types} from "@/utils/webscoket";
import {formatDateTime} from "@/utils/date";

const noticeStore = storeToRefs(useNoticeStore());
// const noticeStore = storeToRefs(useUserStore());
const {readAll} = useNoticeStore();
const userStore = useUserStore();

// 定义变量内容
const state = reactive({
  loading: false,
});
const newsList =ref([]) as any;

/**
 * 初始化数据
 * @returns
 */
const getTableData = async () => {
  state.loading = true;
  newsList.value = noticeStore.state.value.notices;
  state.loading = false;
};

const readAllMessage =async () => {
  readAll();
  for (let item in newsList.value){
    await sendReadingMessage(item);
    await sleep(1000);
  }
}

const sendReadingMessage = async (item?: any) => {
  console.log(item);
    if (item.readStatus == '0'){
        let messages = {
          fromUserId: item.fromUserId,
          fromUserIp: item.fromUserIp,
          toUserId: item.toUserId,
          toUserIp: item.toUserIp,
          type : item.type,
          message : item.message,
          readStatus : item.readStatus,
          sendTime : item.sendTime,
          readTime : item.readTime,
        }
        let data = {
          sessionKey: item.toUserId,
          message: JSON.stringify(messages),
          readStatus: item.readStatus
        }
       sendMsg(data);
  }
}

const sleep = (time:any) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

//点击消息，写入已读
const onNewsClick = (item: any) => {
  newsList.value[item].readStatus = ReadStatus.READ;
  newsList.value[item].readTime = formatDateTime(new Date(),"yyyy-MM-dd HH:mm:ss");
  sendReadingMessage(newsList.value[item]);
  //并且写入pinia
  noticeStore.state.value.notices = newsList.value;
};

// 前往通知中心点击
const onGoToGiteeClick = () => {
  window.open("https://gitee.com/dromara/RuoYi-Vue-Plus/tree/5.X/");
};

onMounted(() => {
  nextTick(() => {
    getTableData();
  });
});
</script>

<style scoped lang="scss">
.layout-navbars-breadcrumb-user-news {
  .head-box {
    display: flex;
    border-bottom: 1px solid var(--el-border-color-lighter);
    box-sizing: border-box;
    color: var(--el-text-color-primary);
    justify-content: space-between;
    height: 35px;
    align-items: center;
    .head-box-btn {
      color: var(--el-color-primary);
      font-size: 13px;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  .content-box {
    height: 300px;
    overflow: auto;
    font-size: 13px;
    .content-box-item {
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      padding-left: 10px;
      &:last-of-type {
        padding-bottom: 12px;
      }
      .content-box-msg {
        color: var(--el-text-color-secondary);
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .content-box-time {
        color: var(--el-text-color-secondary);
      }
      .item-conten {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      &:hover{
        background-color: #ecf5ff;
        --el-text-color-secondary: #409eff;
      }
    }
  }
  .foot-box {
    height: 35px;
    color: var(--el-color-primary);
    font-size: 13px;
    cursor: pointer;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--el-border-color-lighter);
    &:hover {
      opacity: 1;
    }
  }
  :deep(.el-empty__description p) {
    font-size: 13px;
  }
}
</style>
